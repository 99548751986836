<template>
  <v-container>
    <v-row>
      <v-col sm="6">
        <crm-company-tag-manager view_type="master_tag_manager"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import crmCompanyTagManager from "@/components/trade-crm-app/rfi_process/tabs/manage-tags/crm-company-tag-manager";
export default {
  name: "crm-manage-tag-tab",

  components: {crmCompanyTagManager}
}
</script>

<style scoped>

</style>