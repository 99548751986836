var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "crm-blue--text " },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { sm: "7" } },
            [
              _vm.$store.state.crmData.new_request_for_information["id"] === 1
                ? _c(
                    "v-form",
                    { staticClass: "grey lighten-3 mt-4 outline" },
                    [
                      _c("div", [_vm._v(" Description ")]),
                      _c("v-text-field", {
                        model: {
                          value:
                            _vm.$store.state.crmData
                              .new_request_for_information["note"],
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$store.state.crmData
                                .new_request_for_information,
                              "note",
                              $$v
                            )
                          },
                          expression:
                            "$store.state.crmData.new_request_for_information['note']"
                        }
                      }),
                      _c("div", [_vm._v(" Status ")]),
                      _c("v-text-field", {
                        model: {
                          value:
                            _vm.$store.state.crmData
                              .new_request_for_information["status"],
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$store.state.crmData
                                .new_request_for_information,
                              "status",
                              $$v
                            )
                          },
                          expression:
                            "$store.state.crmData.new_request_for_information['status']"
                        }
                      }),
                      _c("div", [_vm._v(" Creator ")]),
                      _c("v-text-field", {
                        model: {
                          value:
                            _vm.$store.state.crmData
                              .new_request_for_information["creator"],
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$store.state.crmData
                                .new_request_for_information,
                              "creator",
                              $$v
                            )
                          },
                          expression:
                            "$store.state.crmData.new_request_for_information['creator']"
                        }
                      }),
                      _c("v-select", {
                        staticClass: "ml-2 mr-2",
                        attrs: {
                          items: [
                            "Tobie Nortje",
                            "Martin Cameron",
                            "Marc du Toit"
                          ],
                          label: "Select asignee"
                        },
                        on: {
                          change: function($event) {
                            return _vm.$store.state.crmData.selected_company.company_tags.push(
                              $event
                            )
                          }
                        }
                      }),
                      _c("v-btn", { on: { click: _vm.submit_workflow } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$store.state.crmData
                              .new_request_for_information["status"] === "OPEN"
                              ? "Update"
                              : "Submit"
                          ) + " "
                        )
                      ]),
                      _c("div", { staticClass: "caption mt-2 outline" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$store.state.crmData
                                .new_request_for_information
                            ) +
                            " "
                        )
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$store.state.crmData.new_request_for_information
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("v-col", { staticClass: "outline light-blue mt-6 mb-3" }, [
            _c("div", [
              _vm._v(
                "buttons for tab " +
                  _vm._s(_vm.$store.state.crmData.active_process_tab)
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }