var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("crm-left-side-bar"),
      _c(
        "v-main",
        { staticClass: "light-grey" },
        [
          false ? _c("rfi-summary-header") : _vm._e(),
          _c(
            "v-container",
            { staticClass: "mt-1 pa-3 outline", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "black--text ml-1 mr-1",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                "fixed-tabs": "",
                                "background-color": "crm-tab",
                                "active-class": "crm-tab-active",
                                dark: ""
                              },
                              model: {
                                value: _vm.$store.state.crmData.active_tab,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$store.state.crmData,
                                    "active_tab",
                                    $$v
                                  )
                                },
                                expression: "$store.state.crmData.active_tab"
                              }
                            },
                            _vm._l(_vm.items, function(item) {
                              return _c(
                                "v-tab",
                                {
                                  key: item.tab,
                                  staticClass: "crm-tab",
                                  attrs: { he: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$store.state.crmData.tab_history.push(
                                        _vm.$store.state.crmData.active_tab
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(item.tab) + " ")]
                              )
                            }),
                            1
                          ),
                          _c("v-tabs-slider"),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.$store.state.crmData.active_tab,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$store.state.crmData,
                                    "active_tab",
                                    $$v
                                  )
                                },
                                expression: "$store.state.crmData.active_tab"
                              }
                            },
                            _vm._l(_vm.items, function(item) {
                              return _c(
                                "v-tab-item",
                                {
                                  key: item.tab,
                                  attrs: { transition: "false" }
                                },
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { fluid: "" } },
                                    [
                                      _c(item.component_name, {
                                        tag: "component"
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "12" } },
                    [
                      _vm.$store.state.crmData.active_tab == 0
                        ? _c(
                            "v-card",
                            { staticClass: "mt-3" },
                            [
                              _c(
                                "v-tabs",
                                {
                                  attrs: {
                                    "fixed-tabs": "",
                                    "background-color": "crm-tab",
                                    "active-class": "crm-tab-active-process",
                                    dark: ""
                                  },
                                  model: {
                                    value:
                                      _vm.$store.state.crmData
                                        .active_process_tab,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$store.state.crmData,
                                        "active_process_tab",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "$store.state.crmData.active_process_tab"
                                  }
                                },
                                _vm._l(_vm.process_items, function(item) {
                                  return _c(
                                    "v-tab",
                                    {
                                      key: item.tab,
                                      staticClass: "crm-tab",
                                      on: {
                                        click: function($event) {
                                          return _vm.$store.state.crmData.process_tab_history.push(
                                            _vm.$store.state.crmData.active_tab
                                          )
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.$store.state.crmData
                                            .active_process_tab,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.$store.state.crmData,
                                            "active_process_tab",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "$store.state.crmData.active_process_tab"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(item.tab) + " ")]
                                  )
                                }),
                                1
                              ),
                              _c("v-tabs-slider"),
                              _c(
                                "v-tabs-items",
                                {
                                  model: {
                                    value:
                                      _vm.$store.state.crmData
                                        .active_process_tab,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$store.state.crmData,
                                        "active_process_tab",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "$store.state.crmData.active_process_tab"
                                  }
                                },
                                _vm._l(_vm.process_items, function(item) {
                                  return _c(
                                    "v-tab-item",
                                    {
                                      key: item.tab,
                                      attrs: { transition: "false" }
                                    },
                                    [
                                      _c(
                                        "v-container",
                                        { attrs: { fluid: "" } },
                                        [
                                          _c(item.component_name, {
                                            tag: "component"
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }