<template>
  <v-container class="crm-blue--text ">
    <v-row>
      <v-col sm="7">
        <!--        <v-btn-->
        <!--          class="ml-1 crm-left-bar-item"-->
        <!--          text-->
        <!--          @click="create_new_workflow('Initiate RFI to requestor')"-->
        <!--          >Initiate RFI to requestor</v-btn-->
        <!--        >-->
        <!--        <v-btn-->
        <!--          class="ml-1 crm-left-bar-item"-->
        <!--          text-->
        <!--          @click="create_new_workflow('Complete RFI on behalf of requestor')"-->
        <!--          >Complete RFI on behalf of requestor-->
        <!--        </v-btn>-->
        <!--        <v-btn-->
        <!--          class="ml-1 crm-left-bar-item"-->
        <!--          text-->
        <!--          @click="create_new_workflow('New RFI (self)')"-->
        <!--          >New RFI (self)</v-btn-->
        <!--        >-->
        <!--        <v-btn class="ml-1 crm-left-bar-item" text @click="clear_current_rfi"-->
        <!--          >Clear</v-btn-->
        <!--        >-->
        <v-form
          class="grey lighten-3 mt-4 outline"
          v-if="$store.state.crmData.new_request_for_information['id'] === 1"
        >
          <div>
            Description
          </div>
          <v-text-field
            v-model="$store.state.crmData.new_request_for_information['note']"
          ></v-text-field>
          <div>
            Status
          </div>
          <v-text-field
            v-model="$store.state.crmData.new_request_for_information['status']"
          ></v-text-field>
          <div>
            Creator
          </div>
          <v-text-field
            v-model="$store.state.crmData.new_request_for_information['creator']"
          ></v-text-field>
          <v-select
            :items="['Tobie Nortje', 'Martin Cameron', 'Marc du Toit']"
            @change="$store.state.crmData.selected_company.company_tags.push($event)"
            class="ml-2 mr-2"
            label="Select asignee"
          >
          </v-select>
          <!--          <v-autocomplete-->
          <!--              :value="new_workflow['company']"-->
          <!--              label="Select Company"-->
          <!--              dense-->
          <!--              outlined-->
          <!--              color="crm-blue&#45;&#45;text"-->
          <!--              class="ma-0 pa-0"-->
          <!--              :items="$store.state.crmData.all_crm_companies"-->
          <!--              @change="load_company_info($event)"/>-->
          <!--          <v-text-field v-model="new_workflow['note']"></v-text-field>-->
          <v-btn @click="submit_workflow"
            >{{
              $store.state.crmData.new_request_for_information['status'] === 'OPEN'
                ? 'Update'
                : 'Submit'
            }}
          </v-btn>
          <div class="caption mt-2 outline">
            {{ $store.state.crmData.new_request_for_information }}
          </div>
          {{ $store.state.crmData.new_request_for_information }}
        </v-form>

        <!--        <v-btn-->
        <!--          class="ml-1 crm-left-bar-item"-->
        <!--          text-->
        <!--          @click="$store.state.crmData.active_tab = 0"-->
        <!--          >Dashboard</v-btn-->
        <!--        >-->
      </v-col>
      <v-col class="outline light-blue mt-6 mb-3">
        <div>buttons for tab {{ $store.state.crmData.active_process_tab }}</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'crm-workflows-tab',

  data() {
    return {};
  },
  methods: {
    submit_workflow() {
      this.$store.state.crmData.requests_for_information_list.push(
        this.$store.state.crmData.new_request_for_information,
      );
      this.create_new_workflow();
    },
    clear_current_rfi() {
      this.$store.state.crmData.new_request_for_information = _.cloneDeep(
        this.$store.state.crmData.request_for_information_template,
      );
    },
    create_new_workflow(rfi_type) {
      this.$store.state.crmData.new_request_for_information = {};
      this.$store.state.crmData.new_request_for_information = _.cloneDeep(
        this.$store.state.crmData.request_for_information_template,
      );
      this.$store.state.crmData.new_request_for_information['id'] = 0;
      this.$store.state.crmData.new_request_for_information['note'] = rfi_type;
    },
  },
};
</script>

<style scoped></style>
