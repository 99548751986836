<template>
  <v-card flat class="crm-outline pb-2">
    <h3 class="crm-selector-header">{{ form_definition['form_name'] }}:</h3>
    <form action="" class="ml-2 mt-2">
      <template v-for="form_value in form_definition['form_fields']">
        <v-row no-gutters :key="form_value['id']">
          <v-col sm="3">
            <span class="crm-blue--text">
            {{ form_value['form_text'] }}:
            </span>
          </v-col>
          <v-col>
            <input type="text"
                   v-model="form_object[form_value['key']]"
                   class="crm-input"
            >
          </v-col>
        </v-row>
      </template>
    </form>
  </v-card>

</template>

<script>
export default {
  name: "crm-input",
  props: [
    'form_definition', // how form should be rendered, and keys to the object below
    'form_object'      // the base object that needs to be changed
  ]
}
</script>

<style scoped>

</style>