var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "crm-outline pb-2", attrs: { flat: "" } },
    [
      _c("h3", { staticClass: "crm-selector-header" }, [
        _vm._v(_vm._s(_vm.form_definition["form_name"]) + ":")
      ]),
      _c(
        "form",
        { staticClass: "ml-2 mt-2", attrs: { action: "" } },
        [
          _vm._l(_vm.form_definition["form_fields"], function(form_value) {
            return [
              _c(
                "v-row",
                { key: form_value["id"], attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { sm: "3" } }, [
                    _c("span", { staticClass: "crm-blue--text" }, [
                      _vm._v(" " + _vm._s(form_value["form_text"]) + ": ")
                    ])
                  ]),
                  _c("v-col", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form_object[form_value["key"]],
                          expression: "form_object[form_value['key']]"
                        }
                      ],
                      staticClass: "crm-input",
                      attrs: { type: "text" },
                      domProps: { value: _vm.form_object[form_value["key"]] },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form_object,
                            form_value["key"],
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ],
                1
              )
            ]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }