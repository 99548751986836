var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "ma-0 pa-0", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { sm: "4" } },
            [
              _c("v-autocomplete", {
                staticClass: "ma-0 pa-0",
                attrs: {
                  label: "Select Company",
                  dense: "",
                  outlined: "",
                  color: "crm-blue--text",
                  items: _vm.$store.state.crmData.all_crm_companies
                },
                on: {
                  change: function($event) {
                    return _vm.load_company_info($event)
                  }
                },
                model: {
                  value: _vm.selected_company,
                  callback: function($$v) {
                    _vm.selected_company = $$v
                  },
                  expression: "selected_company"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-icon",
                {
                  staticClass: "crm-green--text ml-2 mt-2",
                  class: { "crm-icon-spinner": _vm.crm_company_loading }
                },
                [_vm._v("mdi-reload ")]
              ),
              _vm.company_info_changed
                ? _c(
                    "v-btn",
                    {
                      staticClass: "crm-selector-header ml-1",
                      attrs: { text: "" }
                    },
                    [_vm._v("Update ")]
                  )
                : _vm._e(),
              _vm.company_info_changed
                ? _c(
                    "v-btn",
                    {
                      staticClass: "crm-selector-header ml-1",
                      attrs: { text: "" },
                      on: { click: _vm.reset_company_info }
                    },
                    [_vm._v("Reset ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("v-spacer"),
          _c("v-col")
        ],
        1
      ),
      _vm.$store.state.crmData.selected_company.company_info !== null &&
      _vm.crm_company_loading !== true
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { lg: "4", sm: "12" } },
                [
                  _c("crm-input", {
                    attrs: {
                      form_object:
                        _vm.$store.state.crmData.selected_company.company_info,
                      form_definition: _vm.company_form_definition
                    }
                  }),
                  _c("crm-input", {
                    attrs: {
                      form_object:
                        _vm.$store.state.crmData.selected_company.company_info[
                          "postal_code"
                        ],
                      form_definition: _vm.address_form_definition
                    }
                  }),
                  _c("crm-input", {
                    attrs: {
                      form_object:
                        _vm.$store.state.crmData.selected_company.company_info[
                          "user_creator"
                        ],
                      form_definition: _vm.contacts_definition
                    }
                  }),
                  _c("crm-company-tag-manager", {
                    attrs: { view_type: "company_tag_manager" }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "7", sm: "12" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", { staticClass: "ml-5" }, [
                        _c(
                          "div",
                          { staticClass: "crm-outline pb-2" },
                          [
                            _c("h3", { staticClass: "crm-selector-header" }, [
                              _vm._v("HS6 Export/Import Products List")
                            ]),
                            _c("v-autocomplete", {
                              staticClass:
                                "pr-1 ml-2 mr-2 pb-0 font-weight-light mt-3",
                              attrs: {
                                items: _vm.productDropDownItems,
                                label: "Select Product Code",
                                "background-color": "white",
                                "clear-icon": "$clear",
                                clearable: "",
                                color: "#1f3c5f",
                                dense: "",
                                "hide-details": "true",
                                "hide-selected": "",
                                "item-color": "red",
                                outlined: "",
                                "append-icon": "mdi-dolly"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$store.state.crmData.selected_company.company_info[
                                    "HS6_Code"
                                  ].push($event)
                                }
                              }
                            }),
                            _c(
                              "v-card",
                              {
                                staticClass:
                                  "overflow-y-auto crm-outline ml-3 mr-3",
                                attrs: { flat: "", height: "53vh" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "mb-2 pa-1" },
                                  _vm._l(
                                    _vm.$store.state.crmData.selected_company
                                      .company_info["HS6_Code"],
                                    function(code, ix) {
                                      return _c(
                                        "p",
                                        {
                                          key: code.id,
                                          staticClass:
                                            "caption listItem ma-0 mb-2"
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(ix + 1) + " ")
                                          ]),
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [_vm._v(_vm._s(code) + "--")]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.hs6_codeToDescription(code)
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "ma-0 pa-0 pb-1  lighten-3 float-right",
                                              attrs: {
                                                text: "",
                                                "x-small": ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.$store.state.crmData.selected_company.company_info[
                                                    "HS6_Code"
                                                  ].splice(ix, 1)
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  "mdi-close-circle-outline"
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "mt-2 ml-5" },
                        [
                          _c("h3", { staticClass: "crm-selector-header" }, [
                            _vm._v("Market Source/Target List")
                          ]),
                          _c("v-autocomplete", {
                            staticClass: "pr-1 pb-0 font-weight-light mt-3",
                            attrs: {
                              items: _vm.countries_list,
                              label: "Select Country",
                              "background-color": "white",
                              "clear-icon": "$clear",
                              clearable: "",
                              color: "#1f3c5f",
                              dense: "",
                              "hide-details": "true",
                              "hide-selected": "",
                              "item-color": "green",
                              outlined: "",
                              "append-icon": "mdi-dolly"
                            },
                            on: {
                              change: function($event) {
                                _vm.$store.state.crmData.selected_company.company_info[
                                  "trading_markets"
                                ]["to"].push($event)
                              }
                            }
                          }),
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "overflow-y-auto crm-outline ml-3 mr-3",
                              attrs: { flat: "", height: "53vh" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "mb-2 pa-1" },
                                [
                                  _vm._l(
                                    _vm.$store.state.crmData.selected_company
                                      .company_info["trading_markets"]["to"],
                                    function(code, ix) {
                                      return _c(
                                        "p",
                                        {
                                          key: code.id,
                                          staticClass:
                                            "caption listItem ma-0 mb-2"
                                        },
                                        [
                                          _vm._v(" " + _vm._s(ix + 1) + " "),
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [_vm._v(_vm._s(code))]
                                          ),
                                          _vm._v(" " + _vm._s(code) + " "),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "ma-0 pa-0  lighten-3 float-right",
                                              attrs: {
                                                text: "",
                                                "x-small": ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.$store.state.crmData.selected_company.company_info[
                                                    "trading_markets"
                                                  ]["to"].splice(ix, 1)
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  "mdi-close-circle-outline"
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm._l(
                                    _vm.$store.state.crmData.selected_company
                                      .company_info["trading_markets"]["from"],
                                    function(code, ix) {
                                      return _c(
                                        "p",
                                        {
                                          key: code.id,
                                          staticClass:
                                            "caption listItem ma-0 mb-2"
                                        },
                                        [
                                          _vm._v(" " + _vm._s(ix + 1) + " "),
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [_vm._v(_vm._s(code))]
                                          ),
                                          _vm._v(" " + _vm._s(code) + " from "),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "ma-0 pa-0  lighten-3 float-right",
                                              attrs: {
                                                text: "",
                                                "x-small": ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.$store.state.crmData.selected_company.company_info[
                                                    "trading_markets"
                                                  ]["from"].splice(ix, 1)
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  "mdi-close-circle-outline"
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  )
                                ],
                                2
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }