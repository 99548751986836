<template>
       <v-container>
    <v-row>
      <v-col sm="6">

          <pre>        {{$store.state.crmData}}</pre>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "crm-debug-tab"
}
</script>

<style scoped>

</style>