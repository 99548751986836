import { render, staticRenderFns } from "./crm-input.vue?vue&type=template&id=9ab576be&scoped=true&"
import script from "./crm-input.vue?vue&type=script&lang=js&"
export * from "./crm-input.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ab576be",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9ab576be')) {
      api.createRecord('9ab576be', component.options)
    } else {
      api.reload('9ab576be', component.options)
    }
    module.hot.accept("./crm-input.vue?vue&type=template&id=9ab576be&scoped=true&", function () {
      api.rerender('9ab576be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/trade-crm-app/rfi_process/compoments/crm-input.vue"
export default component.exports