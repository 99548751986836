<template>
  <v-container class="crm-blue--text">
    <di> </di>
    <v-row>
      <v-col sm="7">
        <div class="crm-outline">
          <h3 class="crm-selector-header">Open RFI's (make a dropdown list)</h3>

          <p
            v-for="(workflow, ix) in $store.state.crmData.requests_for_information_list"
            :key="workflow.id"
            class="listItem pb-1 pr-2 ml-1"
            @click="edit_rfi(workflow)"
          >
            {{ ix + 1 }} - {{ workflow['note'] }}-
            {{ workflow['creator'] }}

            <v-icon
              class="float-right"
              @click.stop="
                $store.state.crmData.requests_for_information_list.splice(ix, 1)
              "
              >mdi-close-circle-outline</v-icon
            >
          </p>
        </div>
      </v-col>
      <v-col class="outline yellow pa-0 ma-0 mt-5 mb-5" sm="5">
        <v-btn x-small class="ml-1" @click="new_rfi()">New</v-btn>
        <v-btn x-small class="ml-1" @click="set_proces_tab(2)">Approve</v-btn>
        <v-btn x-small class="ml-1" @click="set_proces_tab(3)">Submit</v-btn>
        <v-btn x-small class="ml-1" @click="set_proces_tab(4)">Step a</v-btn>

        <v-btn x-small class="ml-1 light-blue" @click="set_main_tab(1)">Companies</v-btn>
        <v-btn x-small class="ml-1 light-blue" @click="set_main_tab(2)">Contacts</v-btn>
        <v-btn x-small class="ml-1 light-blue" @click="set_main_tab(3)">Tags</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import _ from 'lodash';

export default {
  name: 'crm-dashboard-tab',

  methods: {
    edit_rfi(rfi) {
      this.$store.state.crmData.new_request_for_information = rfi;
      this.$store.state.crmData.active_process_tab = 1;
    },
    set_proces_tab(tab_n) {
      this.$store.state.crmData.active_process_tab = tab_n;
    },
    set_main_tab(tab_n) {
      this.$store.state.crmData.active_tab = tab_n;
    },
    new_rfi() {
      this.$store.state.crmData.active_process_tab = 1;
      // this.$store.state.crmData.new_request_for_information = _.cloneDeep(
      //   this.$store.state.crmData.request_for_information_template,
      // );
    },
  },
};
</script>

<style scoped></style>
