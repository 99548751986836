var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mt-1 pa-3", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        {},
        [
          _c("v-col", { staticClass: "crm-header" }, [
            _vm._v(" Selectors only visible for some pages")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }