var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "crm-left-bar-background",
          attrs: {
            app: "",
            clipped: "",
            "mini-variant": "",
            "mini-variant-width": "100",
            permanent: ""
          }
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "mt-12", attrs: { "no-gutters": "" } },
                [_c("v-col", [_c("div", { staticClass: "text-center ml-2" })])],
                1
              ),
              _c(
                "div",
                { staticClass: "outline pr-1 pl-1 pb-1" },
                [
                  _c("div", { staticClass: "crm-left-bar-menu caption" }, [
                    _vm._v(" RFI ")
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "crm-left-bar-item",
                      attrs: { "x-small": "", width: "100%" },
                      on: {
                        click: function($event) {
                          _vm.$store.state.crmData.active_tab = 0
                        }
                      }
                    },
                    [_vm._v("Dashboard")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "crm-left-bar-item",
                      attrs: { "x-small": "", width: "100%" },
                      on: {
                        click: function($event) {
                          _vm.$store.state.crmData.active_process_tab = 0
                        }
                      }
                    },
                    [_vm._v("New RFI")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "outline pr-1 pl-1 pb-1" },
                [
                  _c("div", { staticClass: "crm-left-bar-menu mt-6 caption" }, [
                    _vm._v(" Admin ")
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "crm-left-bar-item",
                      attrs: { "x-small": "", width: "100%" },
                      on: {
                        click: function($event) {
                          _vm.$store.state.crmData.active_tab = 1
                        }
                      }
                    },
                    [_vm._v("Companies")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "crm-left-bar-item",
                      attrs: { "x-small": "", width: "100%" },
                      on: {
                        click: function($event) {
                          _vm.$store.state.crmData.active_tab = 3
                        }
                      }
                    },
                    [_vm._v("Tags")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "crm-left-bar-item",
                      attrs: { "x-small": "", width: "100%" },
                      on: {
                        click: function($event) {
                          _vm.$store.state.crmData.active_tab = 2
                        }
                      }
                    },
                    [_vm._v("Contacts")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }