<template>
  <v-container>
    <v-row no-gutters class="ma-0 pa-0">
      <v-col sm="4">
        <v-autocomplete
          v-model="selected_company"
          label="Select Company"
          dense
          outlined
          color="crm-blue--text"
          class="ma-0 pa-0"
          :items="$store.state.crmData.all_crm_companies"
          @change="load_company_info($event)"
        />
      </v-col>
      <v-col>
        <v-icon
          class="crm-green--text ml-2 mt-2"
          :class="{'crm-icon-spinner': crm_company_loading}"
          >mdi-reload
        </v-icon>

        <v-btn v-if="company_info_changed" class="crm-selector-header ml-1" text
          >Update
        </v-btn>
        <v-btn
          v-if="company_info_changed"
          text
          class="crm-selector-header ml-1"
          @click="reset_company_info"
          >Reset
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col> </v-col>
    </v-row>
    <!--                    -->
    <v-row
      v-if="
        $store.state.crmData.selected_company.company_info !== null &&
          crm_company_loading !== true
      "
      no-gutters
    >
      <v-col lg="4" sm="12">
        <crm-input
          :form_object="$store.state.crmData.selected_company.company_info"
          :form_definition="company_form_definition"
        />

        <crm-input
          :form_object="$store.state.crmData.selected_company.company_info['postal_code']"
          :form_definition="address_form_definition"
        />

        <crm-input
          :form_object="
            $store.state.crmData.selected_company.company_info['user_creator']
          "
          :form_definition="contacts_definition"
        />

        <crm-company-tag-manager view_type="company_tag_manager" />
      </v-col>

      <v-col lg="7" sm="12">
        <v-row no-gutters>
          <v-col class="ml-5">
            <div class="crm-outline pb-2">
              <h3 class="crm-selector-header">HS6 Export/Import Products List</h3>
              <v-autocomplete
                :items="productDropDownItems"
                label="Select Product Code"
                background-color="white"
                class="pr-1 ml-2 mr-2 pb-0 font-weight-light mt-3"
                clear-icon="$clear"
                clearable
                color="#1f3c5f"
                dense
                hide-details="true"
                hide-selected
                item-color="red"
                outlined
                append-icon="mdi-dolly"
                @change="
                  $store.state.crmData.selected_company.company_info['HS6_Code'].push(
                    $event,
                  )
                "
              ></v-autocomplete>

              <v-card flat class="overflow-y-auto crm-outline ml-3 mr-3" height="53vh">
                <div class="mb-2 pa-1">
                  <p
                    v-for="(code, ix) in $store.state.crmData.selected_company
                      .company_info['HS6_Code']"
                    :key="code.id"
                    class="caption listItem ma-0 mb-2"
                  >
                    <span>{{ ix + 1 }} </span>
                    <span class="font-weight-bold">{{ code }}--</span>
                    {{ hs6_codeToDescription(code) }}
                    <v-btn
                      text
                      x-small
                      class="ma-0 pa-0 pb-1  lighten-3 float-right"
                      @click="
                        $store.state.crmData.selected_company.company_info[
                          'HS6_Code'
                        ].splice(ix, 1)
                      "
                      ><v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </p>
                </div>
              </v-card>
            </div>
          </v-col>
          <v-col class="mt-2 ml-5">
            <h3 class="crm-selector-header">Market Source/Target List</h3>
            <v-autocomplete
              :items="countries_list"
              label="Select Country"
              background-color="white"
              class="pr-1 pb-0 font-weight-light mt-3"
              clear-icon="$clear"
              clearable
              color="#1f3c5f"
              dense
              hide-details="true"
              hide-selected
              item-color="green"
              outlined
              append-icon="mdi-dolly"
              @change="
                $store.state.crmData.selected_company.company_info['trading_markets'][
                  'to'
                ].push($event)
              "
            ></v-autocomplete>
            <v-card flat class="overflow-y-auto crm-outline ml-3 mr-3" height="53vh">
              <div class="mb-2 pa-1">
                <p
                  v-for="(code, ix) in $store.state.crmData.selected_company.company_info[
                    'trading_markets'
                  ]['to']"
                  :key="code.id"
                  class="caption listItem ma-0 mb-2"
                >
                  {{ ix + 1 }}
                  <span class="font-weight-bold">{{ code }}</span>
                  {{ code }}
                  <v-btn
                    text
                    x-small
                    class="ma-0 pa-0  lighten-3 float-right"
                    @click="
                      $store.state.crmData.selected_company.company_info[
                        'trading_markets'
                      ]['to'].splice(ix, 1)
                    "
                    ><v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                </p>
                <p
                  v-for="(code, ix) in $store.state.crmData.selected_company.company_info[
                    'trading_markets'
                  ]['from']"
                  :key="code.id"
                  class="caption listItem ma-0 mb-2"
                >
                  {{ ix + 1 }}
                  <span class="font-weight-bold">{{ code }}</span>
                  {{ code }} from
                  <v-btn
                    text
                    x-small
                    class="ma-0 pa-0  lighten-3 float-right"
                    @click="
                      $store.state.crmData.selected_company.company_info[
                        'trading_markets'
                      ]['from'].splice(ix, 1)
                    "
                    ><v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import crmInput from '@/components/trade-crm-app/rfi_process/compoments/crm-input';
import crmCompanyTagManager from '@/components/trade-crm-app/rfi_process/tabs/manage-tags/crm-company-tag-manager';

export default {
  name: 'crm-manage-companies-tab',

  components: {crmInput, crmCompanyTagManager},

  mounted() {
    this.load_all_crm_companies();
  },

  data() {
    return {
      crm_company_loading: false,
      selected_company: null,
      // current_selected_company_info: null,
      current_selected_company_info_backup: null,
      company_form_definition: {
        form_name: 'Company Details',
        form_fields: [
          {id: 1, form_text: 'Company Name', key: 'Main_Company'},
          {id: 2, form_text: 'Address', key: 'Google_address'},
          {id: 3, form_text: 'Phone Number', key: 'Google_phone_number'},
        ],
      },
      address_form_definition: {
        form_name: 'Address',
        form_fields: [
          {id: 1, form_text: 'Country', key: 'country'},
          {id: 2, form_text: 'Province', key: 'administrative_area_level_1'},
          {id: 3, form_text: 'Municipal Area', key: 'administrative_area_level_2'},
        ],
      },

      contacts_definition: {
        form_name: 'Contacts',
        form_fields: [
          {id: 1, form_text: 'First Name', key: 'first_name'},
          {id: 2, form_text: 'Last Name', key: 'last_name'},
          {id: 3, form_text: 'Email', key: 'email'},
        ],
      },
    };
  },

  computed: {
    countries_list() {
      return JSON.parse(window.localStorage.getItem('dsm_countries')).map(v => {
        return {
          text: `${v.C_Code} - ${v.C_Name}`,
          value: v.C_Code,
        };
      });
    },
    company_info_changed() {
      return !_.isEqual(
        this.current_selected_company_info_backup,
        this.$store.state.crmData.selected_company.company_info,
      );
    },

    productDropDownItems() {
      let list_values = this.productSearchAndSelectedList
        .filter(v => v.in_cube)
        .map(product => {
          return {
            text: product['HS6_Code'] + '-' + product['HS6_Description'],
            value: product['HS6_Code'],
          };
        })
        .sort();

      let all_values = this.productSearchAndSelectedList
        .map(product => {
          return {
            text: product['HS6_Code'] + '-' + product['HS6_Description'],
            value: product['HS6_Code'],
          };
        })
        .sort();

      if (list_values.length === 1) {
        return all_values;
      } else return list_values;
    },
  },

  methods: {
    reset_company_info() {
      this.$store.state.crmData.selected_company.company_info = _.cloneDeep(
        this.current_selected_company_info_backup,
      );
      this.current_selected_company_info_backup = _.cloneDeep(
        this.current_selected_company_info_backup,
      );
    },
    load_company_info(pk) {
      this.crm_company_loading = true;
      // this.current_selected_company_info = {}
      this.current_selected_company_info_backup = {};

      if (this.company_info_changed === true) {
        // alert('Changes detected...');
        console.log('company changes');
      }
      axios
        .post(this.$store.getters.baseUrl + 'get_company_temp/', {pk: pk})
        .then(response => {
          this.$store.state.crmData.selected_company.company_info = JSON.parse(
            response.data,
          )[0]['fields'];
          this.current_selected_company_info_backup = JSON.parse(response.data)[0][
            'fields'
          ];
          // this.$store.state.crmData.DEBUG = this.current_selected_company_info
          this.crm_company_loading = false;
        });
    },
    load_all_crm_companies() {
      this.crm_company_loading = true;
      axios
        .post(this.$store.getters.baseUrl + 'get_all_crm_companies/')
        .then(response => {
          this.$store.state.crmData.all_crm_companies = JSON.parse(response.data);
          this.crm_company_loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
