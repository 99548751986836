var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crm-outline pb-1" },
    [
      _c("h3", { staticClass: "crm-selector-header" }, [_vm._v("Tag List")]),
      _vm.view_type === "master_tag_manager"
        ? _c("v-text-field", {
            staticClass: "ml-2 mr-2 pa-2",
            attrs: {
              counter: "",
              maxlength: "25",
              hint: "This field uses maxlength attribute",
              label: "Enter New Tag",
              color: "crm_blue--text"
            },
            on: {
              change: function($event) {
                return _vm.$store.state.crmData.user.user_tags.push($event)
              }
            }
          })
        : _vm.view_type === "company_tag_manager"
        ? _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("v-select", {
                        staticClass: "ml-2 mr-2",
                        attrs: {
                          items: _vm.$store.state.crmData.user.user_tags,
                          label: "Select tag to add to company tags list"
                        },
                        on: {
                          change: function($event) {
                            return _vm.$store.state.crmData.selected_company.company_tags.push(
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { sm: "1" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "crm-left-bar-item",
                          on: {
                            click: function($event) {
                              return _vm.jump_tab(1, 4)
                            }
                          }
                        },
                        [_vm._v("Manage Tags")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        { staticClass: "mt-1 pa-3", attrs: { flat: "" } },
        _vm._l(_vm.list_to_manage(), function(code, ix) {
          return _c(
            "v-btn",
            {
              key: code.id,
              staticClass: "crm-tag mr-2",
              attrs: { text: "", "x-small": "" },
              on: {
                click: function($event) {
                  _vm.list_to_manage().splice(ix, 1)
                }
              }
            },
            [_vm._v(_vm._s(code) + " X ")]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }