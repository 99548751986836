import { render, staticRenderFns } from "./RfiSummaryHeader.vue?vue&type=template&id=a5b6b4d2&"
import script from "./RfiSummaryHeader.vue?vue&type=script&lang=js&"
export * from "./RfiSummaryHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a5b6b4d2')) {
      api.createRecord('a5b6b4d2', component.options)
    } else {
      api.reload('a5b6b4d2', component.options)
    }
    module.hot.accept("./RfiSummaryHeader.vue?vue&type=template&id=a5b6b4d2&", function () {
      api.rerender('a5b6b4d2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/trade-crm-app/rfi_process/summary_header/RfiSummaryHeader.vue"
export default component.exports