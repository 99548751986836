var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "crm-blue--text" },
    [
      _c("di"),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { sm: "7" } }, [
            _c(
              "div",
              { staticClass: "crm-outline" },
              [
                _c("h3", { staticClass: "crm-selector-header" }, [
                  _vm._v("Open RFI's (make a dropdown list)")
                ]),
                _vm._l(
                  _vm.$store.state.crmData.requests_for_information_list,
                  function(workflow, ix) {
                    return _c(
                      "p",
                      {
                        key: workflow.id,
                        staticClass: "listItem pb-1 pr-2 ml-1",
                        on: {
                          click: function($event) {
                            return _vm.edit_rfi(workflow)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(ix + 1) +
                            " - " +
                            _vm._s(workflow["note"]) +
                            "- " +
                            _vm._s(workflow["creator"]) +
                            " "
                        ),
                        _c(
                          "v-icon",
                          {
                            staticClass: "float-right",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.$store.state.crmData.requests_for_information_list.splice(
                                  ix,
                                  1
                                )
                              }
                            }
                          },
                          [_vm._v("mdi-close-circle-outline")]
                        )
                      ],
                      1
                    )
                  }
                )
              ],
              2
            )
          ]),
          _c(
            "v-col",
            {
              staticClass: "outline yellow pa-0 ma-0 mt-5 mb-5",
              attrs: { sm: "5" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  attrs: { "x-small": "" },
                  on: {
                    click: function($event) {
                      return _vm.new_rfi()
                    }
                  }
                },
                [_vm._v("New")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  attrs: { "x-small": "" },
                  on: {
                    click: function($event) {
                      return _vm.set_proces_tab(2)
                    }
                  }
                },
                [_vm._v("Approve")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  attrs: { "x-small": "" },
                  on: {
                    click: function($event) {
                      return _vm.set_proces_tab(3)
                    }
                  }
                },
                [_vm._v("Submit")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  attrs: { "x-small": "" },
                  on: {
                    click: function($event) {
                      return _vm.set_proces_tab(4)
                    }
                  }
                },
                [_vm._v("Step a")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1 light-blue",
                  attrs: { "x-small": "" },
                  on: {
                    click: function($event) {
                      return _vm.set_main_tab(1)
                    }
                  }
                },
                [_vm._v("Companies")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1 light-blue",
                  attrs: { "x-small": "" },
                  on: {
                    click: function($event) {
                      return _vm.set_main_tab(2)
                    }
                  }
                },
                [_vm._v("Contacts")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1 light-blue",
                  attrs: { "x-small": "" },
                  on: {
                    click: function($event) {
                      return _vm.set_main_tab(3)
                    }
                  }
                },
                [_vm._v("Tags")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }