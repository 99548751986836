<template>
  <v-container class="mt-1 pa-3" fluid>
    <v-row class="">
      <v-col class="crm-header"> Selectors only visible for some pages</v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'rfi-summary-header'
}
</script>
