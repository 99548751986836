<template>
  <div class="crm-outline pb-1">
    <h3 class="crm-selector-header">Tag List</h3>
    <v-text-field
      v-if="view_type === 'master_tag_manager'"
      counter
      maxlength="25"
      hint="This field uses maxlength attribute"
      label="Enter New Tag"
      color="crm_blue--text"
      class="ml-2 mr-2 pa-2"
      @change="$store.state.crmData.user.user_tags.push($event)"
    ></v-text-field>

    <v-container v-else-if="view_type === 'company_tag_manager'">
      <v-row>
        <v-col sm="9">
          <v-select
            :items="$store.state.crmData.user.user_tags"
            @change="$store.state.crmData.selected_company.company_tags.push($event)"
            class="ml-2 mr-2"
            label="Select tag to add to company tags list"
          >
          </v-select>
        </v-col>
        <v-col sm="1">
          <v-btn @click="jump_tab(1, 4)" class="crm-left-bar-item">Manage Tags</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-card flat class="mt-1 pa-3">
      <v-btn
        v-for="(code, ix) in list_to_manage()"
        :key="code.id"
        @click="list_to_manage().splice(ix, 1)"
        class="crm-tag mr-2"
        text
        x-small
        >{{ code }} X
      </v-btn>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'crm-company-tag-manager',
  props: ['view_type'],
  computed: {},
  methods: {
    jump_tab(from, to) {
      this.$store.state.crmData.tab_history.push(from);
      this.$store.state.crmData.active_tab = to;
    },
    list_to_manage() {
      if (this.view_type === 'master_tag_manager') {
        return this.$store.state.crmData.user.user_tags;
      } else if (this.view_type === 'company_tag_manager') {
        return this.$store.state.crmData.selected_company.company_tags;
      } else return this.$store.state.crmData.selected_company.company_tags;
    },
    debug(e) {
      console.log(e);
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
